import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../dashboard.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  reportData: any = {};
  constructor(
    private _header: HeaderService,
    private dashbdService: DashboardService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.totalUserCount();
  }

  totalUserCount() {
    this._header.showLoader();
    this.dashbdService.totalUserCount().subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.reportData = res.data;
          this._header.hideLoader();
        } else {
          this.toastr.error(res.error);
          this._header.hideLoader();
        }
      },(err)=>{
        this._header.hideLoader();
      }
    )
  }

  dealsClaimedCount() {
    this._header.showLoader();
    this.dashbdService.dealsClaimedCount().subscribe(
      (res: any) => {
        if (res.message == 'success') {
          this.reportData.totalDealsClaimedCount = res.data;
          this._header.hideLoader();
        } else {
          this.toastr.error(res.error);
          this._header.hideLoader();
        }
      }
    )
  }

  restaurantCount() {
    this.dashbdService.restaurantCount()
      .subscribe(
        (res: any) => {
          this._header.showLoader();
          if (res.message == 'success') {
            this.reportData.restaurantCount = res.data;
            this._header.hideLoader();
          } else {
            this.toastr.error(res.error);
            this._header.hideLoader();
          }
        }
      )
  }

  dealCount() {
    this.dashbdService.dealCount()
      .subscribe(
        (res: any) => {
          this._header.showLoader();
          if (res.message == 'success') {
            this.reportData.dealCount = res.data;
            this._header.hideLoader();
          } else {
            this.toastr.error(res.error);
            this._header.hideLoader();
          }
        }
      )
  }
}
