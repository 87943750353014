<div id="forgot-password">
  <div class="container-fluid">
    <app-loader></app-loader>
    <div class="row">
      <div class="col-md-12">
        <div class="login-field">
          <div class="login-box">
            <div class="logo"><img routerLink="/" src="../../../../assets/images/daily_special_banner.svg" alt="logo"></div>
            <h3>Reset your Password</h3>
            <p>Enter the email address associated with your account and we will send you the new password.</p>
            <form [formGroup]="resetFrm" (submit)="reset()">
              <div class="form-group">
                <label for="email">Email Address <span class="text-danger">*</span></label>
                <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt=""></span>
                <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                                    <span *ngIf="f.email.errors.required">
                                      Email is required.
                                    </span>
                  <span *ngIf="f.email.errors.email">
                                      Invalid email.
                                    </span>
                </div>
                <button type="submit" class="btn btn-primary">Reset
                  Password
                </button>
              </div>
              <div class="back-signin" routerLink="/">
                <img src="../../../../assets/icons/back.svg" alt="" class="img-fluid"/>
                Back to Sign In</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
