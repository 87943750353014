<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1>The Daily Specials</h1>
        </ng-container>

        <h1 *ngIf="categoryHeading">Manage Restaurants</h1>
        <h1 *ngIf="usersHeading">Manage Cuisines</h1>
        <h1 *ngIf="lessionHeading">Reported Lesson Content</h1>
        <h1 *ngIf="reportedForumHeading">Reported Forum Content</h1>

        <h1 *ngIf="eventsHeading">Events</h1>
        <h1 *ngIf="careersHeading">Careers</h1>
        <h1 *ngIf="sectorHeading">Sectors</h1>
        <h1 *ngIf="profileHeading">Career Profile</h1>
        <h1 *ngIf="newsHeading">News</h1>
        <h1 *ngIf="resourcesHeading">Resources</h1>
        <h1 *ngIf="educationHeading">Education Values</h1>
        <h1 *ngIf="financialHeading">Financial Aid Info</h1>
        <h1 *ngIf="quizHeading">Quiz</h1>
        <h1 *ngIf="feedbackHeading">Feedback</h1>
        <h1 *ngIf="partnerHeading">Partners</h1>
        <h1 *ngIf="notificationHeading">Notifications</h1>
        <h1 *ngIf="manageTagsHeading">Manage Tags</h1>
    </div>

    <div class="right-sec">
        <div class="btn-group" dropdown placement="bottom right">
            <button id="button-alignment" dropdownToggle type="button" class="btn dropdown-toggle"
                aria-controls="dropdown-alignment">
                {{username}} <img class="drop-down-arrow" src="../../../assets/icons/down-arrow-white.png" alt="">
            </button>
            <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-alignment">
                <li role="menuitem">
                  <a class="dropdown-item"
                        routerLink="/edit-profile">Change Password</a>
                </li>
                <li role="menuitem">
                  <a (click)="openModal(template)" class="dropdown-item">Logout</a>
                </li>
            </ul>
        </div>

    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-cancel" (click)="cancel()">No</button>
            <button class="btn btn-blue" (click)="confirm()" routerLink="/logout">Yes</button>
        </div>
    </div>
</ng-template>

