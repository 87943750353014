import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard.service';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { EditProfileComponent } from './auth/components/edit-profile/edit-profile.component';

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    path: 'category',
    loadChildren: () =>
      import('./views/category/category.module').then(
        (m) => m.CategoryModule
      ),
  },  
  {
    canActivate: [AuthGuard],
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    canActivate: [AuthGuard],
    path: 'edit-profile',
    component: EditProfileComponent
  },  
  
  {
    canActivate: [AuthGuard],
    path: 'manage-cuisines',
    loadChildren: () =>
      import('./views/manage-cuisines/manage-cuisines.module').then((m) => m.ManageCuisinesModule),
  },
  
  {
    canActivate: [AuthGuard],
    path: 'manage-tags',
    loadChildren: () =>
      import('./views/manage-tags/manage-tags.module').then((m) => m.ManageTagsModule),
  },

  {
    canActivate: [AuthGuard],
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  // {
  //   canActivate: [AuthGuard],
  //   path:'reported-forum',
  //   loadChildren:()=>
  //   import('./views/reported-forum/reported-forum.module').then((m)=>m.ReportedForumModule),
  // },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./views/notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
