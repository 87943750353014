import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public isLoader:boolean=true;;
  welcomeUserMsg = new BehaviorSubject(false);
  eventHeading = new BehaviorSubject(false);
  careersHeading = new BehaviorSubject(false);
  sectorHeading = new BehaviorSubject(false);
  profileHeading = new BehaviorSubject(false);
  resourcesHeading = new BehaviorSubject(false);
  newsHeading = new BehaviorSubject(false);
  educationHeading = new BehaviorSubject(false);
  financialHeading = new BehaviorSubject(false);
  quizHeading = new BehaviorSubject(false);
  usersHeading = new BehaviorSubject(false);
  feedbackHeading = new BehaviorSubject(false);
  partnerHeading = new BehaviorSubject(false);
  notificationHeading = new BehaviorSubject(false);

  categoryHeading = new BehaviorSubject(false);
  lessionHeading = new BehaviorSubject(false);
  reportedForumHeading = new BehaviorSubject(false);
  manageTagsHeading = new BehaviorSubject(false);

  hideLoader(){ 
    return this.isLoader=true;
  }
  showLoader(){
    return this.isLoader=false;
  }
}
