import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class AuthGuard  {
    constructor(public authService: AuthService,public router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const currentUser = this.authService.currentUserValue;
        let token = localStorage.getItem('userToken')
        if (currentUser && token) {
            return true;
        };

        this.authService.logout();
        this.router.navigateByUrl('/');
        return false;
    }
}