import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  url: any = environment.API_URL;
  public headers = new HttpHeaders({
    Authorization: 'Basic ' + btoa('asDF@SHS@30' + ':' + '30@a87sDF@SHS@uht1RD0E'),
    accessToken: localStorage.getItem('userToken')
  })

  constructor(
    private http: HttpClient
  ) { }

  createCategory(data: any) {
    return this.http.post<any>(this.url + 'lesson/createLessonCategory', data);
  }
  
  updateCategory(data: any) {
    return this.http.patch<any>(this.url + 'lesson/updateLessonCategory', data);
  }

  getRestaurantById(id: any) {
    return this.http.get<any>(this.url + `admin/user-profile/${id}`, { headers: this.headers });
  }

  getAllRestaurants(page, pageLimit) {
    if (page && pageLimit) {
      return this.http.get<any>(this.url + `admin/restaurant-list?page=${page}&limit=${pageLimit}`, { headers: this.headers });
    }
    else {
      return this.http.get<any>(this.url + `user/restaurant-list-for-admin`, { headers: this.headers });
    }
  }


  changeStatus(id: any, body) {
    return this.http.put<any>(this.url + `admin/admin-update-user/${id}`, body, { headers: this.headers });
  }

  restaurantApprovedEmail(id: any) {
    return this.http.get<any>(this.url + `admin/restaurant-approved-email/${id}`, { headers: this.headers });
  }

}
