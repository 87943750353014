<app-layout></app-layout>

<div class="content">
    <app-loader></app-loader>
    <div class="edit-profile">
        <div class="row">
            <div class="col-md-4 change-pass1">
                <h3>Change Password</h3>
                <form [formGroup]="changePassFrm" (submit)="changePasswordSubmit()">
                    <div class="form-group">
                        <label>Old Password</label>
                        <mat-form-field appearance="fill" class="form-control">
                            <input matInput [type]="hide ? 'password' : 'text'" value="" placeholder="Old Password" formControlName="oldPassword"
                                (keyup)="checkPassword($event)">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                        <div *ngIf="submitted && f.oldPassword.errors" class="alert alert-danger">
                            <span *ngIf="f.oldPassword.errors.required">
                                Password is required.
                            </span>
                            <span *ngIf="f.oldPassword.errors.minlength">
                                Old password must contain at least 6 characters.
                            </span>
                            <span *ngIf="f.oldPassword.errors.maxlength">
                                New password must contain max 30 characters.
                            </span>
                        </div>
                        <div *ngIf="correctPassword && changePassFrm.value.oldPassword" class="alert alert-danger">
                            <span>Old password is incorrect</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="form-group">
                            <label for="">New Password</label>
                            <mat-form-field appearance="fill" class="form-control">
                                <input matInput [type]="hide1 ? 'password' : 'text'" value=""
                                    formControlName="newPassword" placeholder="New Password" maxlength="10">
                                <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                    <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                            <div *ngIf="submitted && f.newPassword.errors" class="alert alert-danger">
                                <span *ngIf="f.newPassword.errors.required">
                                    New password is required.
                                </span>
                                <span *ngIf="f.newPassword.errors.minlength">
                                    New password must contain at least 6 characters.
                                </span>
                                <span *ngIf="f.newPassword.errors.maxlength">
                                    New password must contain max 30 characters.
                                </span>
                                <span *ngIf="f.newPassword.errors.pattern">
                                    Please choose a stronger password. It should contain at least 6 Characters, maximum 10 Characters, 1 Lowercase letter, 1 Uppercase letter, 1 Special character and 1 Number.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="">Confirm Password</label>
                        <mat-form-field appearance="fill" class="form-control">
                            <input matInput [type]="hide2 ? 'password' : 'text'" value=""
                                formControlName="confirmPassword" placeholder="Confirm Password" maxlength="10">
                            <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                        <div *ngIf="submitted && (f.confirmPassword.errors || changePassFrm.errors?.passwordNotMatch)"
                            class="alert alert-danger">
                            <span *ngIf="f.confirmPassword.errors?.required">
                                Confirm password is required.
                            </span>
                            <span *ngIf="f.confirmPassword.errors?.minlength && !changePassFrm.errors?.passwordNotMatch">
                                Confirm password must contain at least 6 characters. </span>
                            <span *ngIf="f.confirmPassword.errors?.maxlength && !changePassFrm.errors?.passwordNotMatch">
                                confirm password must contain max 30 characters.
                            </span>
                            <span *ngIf="changePassFrm.errors?.passwordNotMatch">New password and confirm password
                                should be match.</span>
                        </div>
                    </div>

                    <div class="form-group" style="margin-top: 10px;">
                        <button type="submit" class="btn btn-blue">Change Password</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>