import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth/services/auth.service';
import { Router } from "@angular/router";
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'daily-specials-admin';
  status = 'ONLINE';
  isConnected = true;
  currentUser: any;
  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
  }
  constructor(
    private connectionService: ConnectionService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private bnIdle: BnNgIdleService,
    public router: Router,
  ) {

    this.bnIdle.startWatching(60 * 120).subscribe((res) => {
      if (res) {
        console.log("session expired");
        this.currentUser = this.authService.currentUserValue;
        if (this.currentUser) {
          this.authService.logout()
        }
        this.router.navigateByUrl("/");
        this.toastrService.error('session expired');
      }
    })

    this.connectionService.monitor().subscribe((isConnected: any) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
        this.authService.logout()
        this.toastrService.error('Connection Lost');
      }
    })
  }

}
