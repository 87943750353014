<div class="sidebar">
  <div class="logo">
    <img routerLink="/dashboard" src="../../../assets/images/daily_special_banner.svg" alt="logo" />
  </div>
  <a routerLink="/dashboard" routerLinkActive="active">
    <span class="sidebar-icon">
      <img class="black-icon" src="../../../assets/icons/dashboard1.svg" alt="" />
      <img class="white-icon" src="../../../assets/icons/sidebar/dashboard2.svg" alt="" />
    </span>
    Dashboard</a>
  <a routerLink="/category" routerLinkActive="active">
    <span class="sidebar-icon">
      <img class="black-icon" src="../../../assets/icons/manageRestaurants.svg" alt="" />
      <img class="white-icon" src="../../../assets/icons/sidebar/deals2.svg" alt="" />
    </span>
    Manage Restaurants
  </a>

  <a routerLink="/manage-cuisines/add" routerLinkActive="active">
    <span class="sidebar-icon">
      <img class="black-icon" src="../../../assets/images/usersBlack.png" alt="" />
      <img class="white-icon" src="../../../assets/images/userWhite.png" alt="" />
    </span>
    Manage Cuisines
  </a>

  <a routerLink="/manage-tags/add" routerLinkActive="active">
    <span class="sidebar-icon">
      <img class="black-icon" src="../../../assets/images/tags-black.svg" alt="" />
      <img class="white-icon" src="../../../assets/images/tags-white.svg" alt="" />
    </span>
    Manage Tags
  </a>

</div>