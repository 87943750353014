import { Component } from '@angular/core';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent {
  constructor(public _header:HeaderService) { }
}
