import { Component, OnInit, TemplateRef } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CategoryService } from 'src/app/views/category/services/category.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  username: any = '';
  welcomeUserMsg: boolean = false;
  categoryHeading: boolean = false;
  usersHeading: boolean = false;
  lessionHeading: boolean = false;
  reportedForumHeading: boolean = false;


  eventsHeading: boolean = false;
  careersHeading: boolean = false;
  sectorHeading: boolean = false;
  profileHeading: boolean = false;
  resourcesHeading: boolean = false;
  newsHeading: boolean = false;
  educationHeading: boolean = false;
  financialHeading: boolean = false;
  quizHeading: boolean = false;
  manageTagsHeading: boolean = false;
  
  feedbackHeading: boolean = false;
  partnerHeading: boolean = false;
  notificationHeading: boolean = false;

  
  modalRef: any;

  constructor(private _header: HeaderService, public router: Router, private modalService: BsModalService,private authService:AuthService, private catService: CategoryService, private toastr:ToastrService ) {
    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });
    this._header.eventHeading.subscribe((res) => {
      this.eventsHeading = res;
    });
    this._header.careersHeading.subscribe((res) => {
      this.careersHeading = res;
    });
    this._header.sectorHeading.subscribe((res) => {
      this.sectorHeading = res;
    });
    this._header.profileHeading.subscribe((res) => {
      this.profileHeading = res;
    });
    this._header.resourcesHeading.subscribe((res) => {
      this.resourcesHeading = res;
    });
    this._header.newsHeading.subscribe((res) => {
      this.newsHeading = res;
    });
    this._header.educationHeading.subscribe((res) => {
      this.educationHeading = res;
    });
    this._header.financialHeading.subscribe((res) => {
      this.financialHeading = res;
    });
    this._header.quizHeading.subscribe((res) => {
      this.quizHeading = res;
    });
    this._header.usersHeading.subscribe((res) => {
      this.usersHeading = res;
    });
    this._header.feedbackHeading.subscribe((res) => {
      this.feedbackHeading = res;
    });
    this._header.partnerHeading.subscribe((res) => {
      this.partnerHeading = res;
    });
    this._header.notificationHeading.subscribe((res) => {
      this.notificationHeading = res;
    });
    this._header.categoryHeading.subscribe((res) => {
      this.categoryHeading = res;
    });
    this._header.lessionHeading.subscribe((res) => {
      this.lessionHeading = res;
    });
    this._header.reportedForumHeading.subscribe((res) => {
      this.reportedForumHeading = res;
    });

    this._header.manageTagsHeading.subscribe((res) => {
      this.manageTagsHeading = res;
    });
  }

  ngOnInit(): void {

    let email = localStorage.getItem('adminEmail');
    let userData1 = email.split('@');
    this.username = userData1[0];  
                    
         
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    
  }
  confirm(){
    this.modalRef.hide();
  }
  cancel(){
    this.modalRef.hide();
  }
}
