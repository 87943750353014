import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { FormControl, FormGroup,Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit, OnDestroy {
  changePassFrm:FormGroup;
  updateProfFrm:FormGroup;
  submitted:boolean=false;
  correctPassword:boolean = true;
  userType:any=1;
  LoggeUserdData:any;
  hide = true;
  hide1 = true;
  hide2 = true;
  constructor(
    private _header: HeaderService,
    private authService:AuthService,
    private toastr:ToastrService,
    private router:Router
    ) {}

  passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[!@#$%&*^])[\w !@#$%&*^]/;
  initChangePassFrm(){
    this.changePassFrm= new FormGroup({
      oldPassword:new FormControl('',[
        Validators.required
      ]),
      newPassword:new FormControl('',[
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
        Validators.pattern(this.passwordRegex)
      ]),
      confirmPassword: new FormControl('',[
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30)
      ])
    }, {
      validators:this.matchPassword.bind(this)
    }
    
    )
  }

  matchPassword(formGroup: FormGroup) {
    const { value: newPassword } = formGroup.get('newPassword');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return newPassword === confirmPassword ? null : { passwordNotMatch: true };
  }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.LoggeUserdData=this.authService.currentUserValue;    
    
    /*init change password form */
    this.initChangePassFrm();
  }
  get f() {
    return this.changePassFrm.controls;
  }
  
  changePasswordSubmit(){ 
    if(this.correctPassword){
      return
    }
    this.submitted = true;     
    if (this.changePassFrm.invalid || this.changePassFrm.pristine || this.changePassFrm.errors?.passwordNotMatch) return;
    this.submitted = false;
    let email   = localStorage.getItem('adminEmail')    ;

    let user: any = {
      email: email,
      currentPassword: this.changePassFrm.value.oldPassword,
      newPassword:this.changePassFrm.value.newPassword
    };

    this._header.showLoader();
    
    this.authService.changePassword(user).subscribe(
      (res: any) => { 
        console.log('%cres----> ', res)
        if (res.statusCode == 200) { //ask for status code
          this.toastr.success("Password changed successfully");
          this.changePassFrm.reset();
          this.router.navigateByUrl('/dashboard');
          this._header.hideLoader();
        } else {
          this.toastr.error(res.message);
        }
      },
      (err) => {
        this._header.hideLoader();
        this.toastr.error(err?.error?.message);
      }
    );
    
  }

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  checkPassword(e:any){
    this.authService.checkOldPassword({currentPassword: e.target.value}).subscribe((res:any)=>{
        this.correctPassword = res?.data == false ? true : false;
    })
  }
}
