<app-layout></app-layout>
<div class="content">
  <div class="row">
    <app-loader></app-loader>
    <div class="col-12">
      <h3 class="heading-main">Reporting</h3>
      <p class="white-bg"></p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="total-counts row">
          <div class="col-12 col-sm-12 col-md-3">
            <div class="total-count mx-0 row total-users" style="cursor: unset !important;">
              <div class="col-12 col-sm-12 col-md-4">
                <div class="dashboard-report-icon">
                  <img src="../../../assets/icons/total-users.svg" alt="" class="img-fluid" />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8">
                <h2>{{ reportData?.users }}</h2>
                <p>Total Number of Registered Customers</p>
              </div>
            </div>
          </div>
  
          <div class="col-12 col-sm-12 col-md-3">
            <div class="total-count total-events row mx-0" routerLink="/category">
              <div class="col-12 col-sm-12 col-md-4">
                <div class="dashboard-report-icon">
                  <img src="../../../assets/icons/restaurant.svg" height="60" width="60" alt="" class="img-fluid" />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8">
                <h2>{{ reportData?.restaurants }}</h2>
                <p>Total Number of Registered Restaurants</p>
              </div>
            </div>
          </div>
  
          <div class="col-12 col-sm-12 col-md-3">
            <div class="total-count total-careers row mx-0"  style="cursor: unset !important;">
              <div class="col-12 col-sm-12 col-md-4">
                <div style="background-color: #f1f1f1" class="dashboard-report-icon">
                  <img src="../../../assets/icons/ads_listed.svg" alt="" class="img-fluid" />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8">
                <h2>{{ reportData?.activeAds }}</h2>
                <p>Total Number of Active Ads</p>
              </div>
            </div>
          </div>
  
          <div class="col-12 col-sm-12 col-md-3">
            <div class="total-count total-news row mx-0" style="cursor: unset !important;">
              <div class="col-12 col-sm-12 col-md-4">
                <div class="dashboard-report-icon">
                  <img src="../../../assets/icons/saved_ads.svg" alt="" class="img-fluid"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8">
                <h2>{{ reportData?.totalAdSaved }}</h2>
                <p>Total Number of Ads Saved</p>
              </div>
            </div>
          </div>
  
          <div class="col-12 col-sm-12 col-md-3">
            <div class="total-count total-news row mx-0" style="cursor: unset !important;">
              <div class="col-12 col-sm-12 col-md-4">
                <div class="dashboard-report-icon">
                  <img src="../../../assets/icons/view_ads.svg" alt="" class="img-fluid" />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8">
                <h2>{{ reportData?.totalAdViews }}</h2>
                <p>Total Count of Ads Views</p>
              </div>
            </div>
          </div>
  
        
          <!-- <div class="col-12 col-sm-12 col-md-3">
            <div class="total-count total-news row mx-0" routerLink="/news/list">
              <div class="col-12 col-sm-12 col-md-4">
                <div class="dashboard-report-icon">
                  <img src="../../../assets/icons/manageRestaurants.svg" alt="" height="60" width="60" class="img-fluid" style="background-color: #d7eaef;border-radius: 100%;padding: 11px;"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8">
                <h2>{{ reportData?.totalDealsClaimedCount }}</h2>
                <p>Total Revenue Generated</p>
              </div>
            </div>
          </div> -->
  
        </div>
      </div>
    </div>
  </div>
</div>