<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="container-fluid vh-100">
  <app-loader></app-loader>
  <div class="row vh-100 align-items-center justify-content-center">
    <div class="main-auth-div">
      <div class="row h-100">
        <div class="login-banner">
          <img src="../../../../assets/images/daily_special_banner.svg" alt="login-banner" style="object-fit: none !important;">
        </div>
        <div class="login-field">
          <div class="login-box">
            <h3>Sign In</h3>
            <p class="mb-3">&nbsp;</p>
            <form [formGroup]="loginForm" (submit)="login()">
              <div class="form-group">
                <label for="email">Email Address <span class="text-danger">*</span></label>
                <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt=""></span>
                <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="alert-text">
                  <span *ngIf="f.email.errors.required">
                    Email is required.
                  </span>
                  <span *ngIf="f.email.errors.email">
                    Invalid email.
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="email">Password <span class="text-danger">*</span></label>
                <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt=""></span>
                <input [type]="password" class="form-control" placeholder="Password" id="password"
                  formControlName="password">
                <button type="button" (click)="onClick()" style="background: transparent;
                       border: 0;
                       position: absolute;
                       right: 6px;
                       top: 44px;">
                  <i class="fa fa-eye" style="font-size: 20px;    opacity: 0.5;" aria-hidden="true" *ngIf="!show"></i>
                  <i class="fa fa-eye-slash" style="font-size: 20px;    opacity: 0.5;" aria-hidden="true"
                    *ngIf="show"></i>
                </button>
                <div *ngIf="submitted && f.password.errors" class="alert-text">
                  <span *ngIf="f.password.errors.required">
                    Password is required.
                  </span>
                  <span *ngIf="f.password.errors.minlength">
                    Password must contain at least 6 characters.
                  </span>
                </div>
                <div class="form-group mb-0">
                  <div class="forgot-pass text-end" routerLink="/forgot-password">Forgot Password?</div>
                  <button type="submit" class="btn btn-primary">Sign In</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>