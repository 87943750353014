import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedRoutingModule } from './shared-routing.module';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LayoutComponent } from './layout/layout.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoaderComponent } from './loader/loader.component';


@NgModule({
  declarations: [HeaderComponent, SidebarComponent, LayoutComponent, LoaderComponent],
  imports: [CommonModule, BsDropdownModule.forRoot(), SharedRoutingModule, ModalModule.forRoot(),],
  exports: [SidebarComponent, HeaderComponent, LayoutComponent, LoaderComponent],
})
export class SharedModule { }
