import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from "ngx-toastr";
import { AuthGuard } from './_helpers/auth.guard.service';
import { AuthService } from './auth/services/auth.service';
import { SharedService } from './shared/services/shared.service';

// ngx-bootstrap
 
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    DashboardModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientModule,
    SharedModule,
  ],
  providers: [
    BnNgIdleService, // add it to the providers of your module
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    AuthService,
    SharedService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
