import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    url: any = environment.API_URL;

    constructor(
        private http: HttpClient,
        private router:Router
    ) { }

    public loginHeaders = new HttpHeaders({
        Authorization: 'Basic ' + btoa('asDF@SHS@30' + ':' + '30@a87sDF@SHS@uht1RD0E'),
    })

    public headers = new HttpHeaders({
        Authorization: 'Basic ' + btoa('asDF@SHS@30' + ':' + '30@a87sDF@SHS@uht1RD0E'),
    }).set('accessToken', localStorage.getItem('userToken'));



    public get currentUserValue() {
        let data = localStorage.getItem('currentUser');
        let obj: Object = {};
        if (data !== 'undefined')
            return localStorage.getItem('currentUser');
        return false;
    }

    public get language() {
        let lang = localStorage.getItem('lang');
        console.log('lang', lang);
        if (lang) {
            return lang;
        }
        return false;
    }

    login(user: any) {
        return this.http.post(this.url + 'admin/admin-login', user,{ headers: this.loginHeaders });
    }

    reset(email: any) {
        return this.http.post<any>(this.url + 'admin/forgot-password', email,{ headers: this.loginHeaders });
    }

    checkOldPassword(email: any) {
        let headers = new HttpHeaders({
            Authorization: 'Basic ' + btoa('asDF@SHS@30' + ':' + '30@a87sDF@SHS@uht1RD0E'),
        }).set('accessToken', localStorage.getItem('userToken'));
        return this.http.post<any>(this.url + 'admin/check-old-password', email,{ headers: headers });
    }

    logout() {
        localStorage.removeItem('adminEmail');
        localStorage.removeItem('userToken');
        localStorage.removeItem('lang');
        this.router.navigateByUrl('/');
    }

    updateuser(data: any) {
        return this.http.post(this.url + 'user/create-profile', data);
    }

    changePassword(data: any) {
        let headers = new HttpHeaders({
            Authorization: 'Basic ' + btoa('asDF@SHS@30' + ':' + '30@a87sDF@SHS@uht1RD0E'),
        }).set('accessToken', localStorage.getItem('userToken'));
        return this.http.put(this.url + 'admin/change-password', data, { headers:  headers});
    }
}
