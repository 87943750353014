import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { HeaderService } from 'src/app/shared/services/header.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  submitted: boolean = false;
  userType:any =1;
  resetFrm:UntypedFormGroup;
  constructor(
    private authService:AuthService,
    private toastr:ToastrService,
    private router:Router,
    private _header: HeaderService
  ) { }

  ngOnInit(): void {
    this.resetFrm=new UntypedFormGroup({
      email:new UntypedFormControl('',[Validators.required,Validators.email])
    })
  }
  get f() {
    return this.resetFrm.controls;
  }
  reset() {
    
    this.submitted = true;
    let user: any = {
      email: this.resetFrm.value.email,
      userType: this.userType
    };
    console.log('userType', user);
    if (this.resetFrm.invalid || this.resetFrm.pristine) return;
    this.submitted = false;
    this._header.showLoader();
    
    this.authService.reset(user).subscribe(
      (res: any) => { console.log("pppp",JSON.stringify(res)); // ask for status code --- aksa
      
      this._header.hideLoader();
        if (res.statusCode == 200) {
          this.toastr.success(res.data);
        }
        else{
          this.toastr.error(res.error);
        }
      },
      (err) => {
        this._header.hideLoader();
        this.toastr.error(err?.error?.message);
      }
    );
  }
}
