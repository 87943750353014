import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { AuthService } from '../../services/auth.service';
import { HeaderService } from 'src/app/shared/services/header.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitted: boolean = false;
  error = error.err;
  userType: any = 1;
  hidePass='password';
  showPass='text';
  password:any;
  show = false;
  loginForm : UntypedFormGroup;

  constructor(
    private router:Router,
    private authService:AuthService,
    private toastr:ToastrService,
    private _header: HeaderService
  ) { }

  ngOnInit(): void {
    this.password = this.hidePass;
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [
        Validators.required,
        // Validators.minLength(6),
      ]),
    });
  }

  onClick() {
    if (this.password === 'password') {
      this.password = this.showPass;
      this.show = true;
    } else {
      this.password = this.hidePass;
      this.show = false;
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;
    let user: any = {
      email: this.loginForm.value.email?.toLowerCase(),
      password: this.loginForm.value.password,
      // userType: this.userType,
    };
    this._header.showLoader();

    this.authService.login(user).subscribe(
      (res: any) => { 
        if(res.status)
            localStorage.setItem('currentUser', res.data.userId);
            localStorage.setItem('adminEmail', user.email);
            localStorage.setItem('userToken',res.data.token);
            this.router.navigateByUrl('/dashboard');    
            this._header.hideLoader();  
      },
      (err) => {
        this._header.hideLoader();
        if(err?.error?.message){
          this.toastr.error(err.error.message);
        }else{
          this.toastr.error(this.error);
        }
      }
    );
  }
}
