import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  url: any = environment.API_URL;
  constructor(
    private http:HttpClient,
  ) { }

  public headers = new HttpHeaders({
    Authorization: 'Basic ' + btoa('asDF@SHS@30' + ':' + '30@a87sDF@SHS@uht1RD0E'),
    accessToken: localStorage.getItem('userToken')
  })

  totalUserCount(){
    return this.http.get<any>(this.url+'admin/admin-dashboard',{headers: this.headers})
  }

  restaurantCount(){
    return this.http.get<any>(this.url+'user/total-restaurant-count',{headers: this.headers})
  }

  dealCount(){
    return this.http.get<any>(this.url+'deal/deal-count',{headers: this.headers})
  }

  dealsClaimedCount(){
    return this.http.get<any>(this.url+'admin/claimed-deal-count',{headers: this.headers})
  }
}
